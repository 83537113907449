<template>
  <div>
    <withdrawal-request-sidebar />
    <save-financial-adjustment-sidebar />
    <div style="position: relative">
      <app-breadcrumb :breadcrumbTitle="false">
        <b-button
          variant="primary"
          @click="openNewFinancialAdjustmentSidebar({ saveAction })"
          style="position: absolute; right: 13"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Novo lançamento</span>
        </b-button>
      </app-breadcrumb>
    </div>
    <b-row class="match-height">
      <b-col md="8">
        <b-card no-body class="card-statistics">
          <b-card-header class="p-2">
            <b-card-title>Totalizadores</b-card-title>
          </b-card-header>
          <b-card-body class="px-3">
            <b-row class="justify-content-around justify-content-lg-between flex-wrap">
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="commission-totalizer-title">Crédito</span>
                    <br>
                    <h4 class="font-weight-bolder mb-0">
                      {{ credit? credit.amount:0 | toCurrency }}
                    </h4>
                    <b>{{ credit? credit.total:0 }}</b>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="commission-totalizer-title">Débito</span>
                    <br>
                    <h4 class="font-weight-bolder mb-0">
                      {{ debt? debt.amount:0 | toCurrency }}
                    </h4>
                    <b>{{ debt? debt.total:0 }}</b>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="commission-totalizer-title">Quantidade total</span>
                    <br>
                    <h4 class="font-weight-bolder mb-0">
                      {{ total_count || 0 }}
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card no-body class="earnings-card">
          <b-card-body>
            <!-- apex chart -->
            <template v-if="total_count > 0">
              <vue-apex-charts 
                height="130"
                :options="chartOptions"
                :series="chartSeries"
              />
            </template>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BPopover,
  BButton,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import VueApexCharts from "vue-apexcharts";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import WithdrawalRequestSidebar from "@/modules/withdrawal/components/WithdrawalRequestSidebar";
import SaveFinancialAdjustmentSidebar from "@/modules/financial_adjustment/components/SaveFinancialAdjustmentSidebar";
import * as types from "../store/types";
import * as creditTypes from "@/constants/credit_types";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton,
    AppBreadcrumb,
    WithdrawalRequestSidebar,
    SaveFinancialAdjustmentSidebar,
  },
  props: {
    totalizer: Array,
    saveAction: Function
  },
  computed: {
    credit: function () {
      return _.find(this.totalizer, { credit_type: creditTypes.CREDIT });
    },
    debt: function () {
      return _.find(this.totalizer, { credit_type: creditTypes.DEBT });
    },
    total_count: function(){
      var total = 0
      if(this.credit){
        total += this.credit.total
      }
      if(this.debt){
        total += this.debt.total
      }
      return total
    },
    chartOptions: function(){
      return {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
          width: '100%'
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        labels: ['Débito', 'Crédito'],
        colors: ['#116589', '#6D0000'],
        tooltip: {
          x: {
            show: false
          },
          y: {
            formatter: function(value, series) {
              // use series argument to pull original string from chart data
              return parseFloat(value).toFixed(2);
            }
          }
        },
        stroke: { width: 0 },
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                  formatter() {
                    return 'Total'
                  },
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    return `${parseFloat(val).toFixed(2)}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: 'Total',
                  formatter() {
                    return '100%'
                  },
                },
              },
            },
          },
        },
      }
    },
    chartSeries: function(){
      var debtPercentage = (this.debt?.total || 0 * 100) / this.total_count * 100
      var creditPercentage = (this.credit?.total || 0 * 100) / this.total_count * 100
      return [debtPercentage, creditPercentage]
    }
  },
  methods: {
    ...mapActions({
      openNewFinancialAdjustmentSidebar: types.OPEN_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR,
    }),
  }
};
</script>

<style lang="scss">
.commission-totalizer-title {
  font-weight: 500;
  font-size: 1.2rem;
}
</style>
