<template>
  <div>
    <b-sidebar
      id="sidebar-adjustment-details-adjustment"
      sidebar-class="sidebar-lg"
      :visible="adjustmentDetailsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateAdjustmentDetailsSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Detalhes do lançamento</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="m-2" v-if="adjustment">
          <b-row>
            <b-col cols="6" class="mb-1">
              Nome: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ adjustment.name }} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Interessado: 
            </b-col>
            <b-col cols="6" class="mb-1" v-if="adjustment.consultant">
              {{ adjustment.consultant.name}} 
            </b-col>
            <b-col cols="6" class="mb-1" v-else-if="adjustment.department">
              {{ adjustment.department.name}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Data da solicitação:  
            </b-col>
            <b-col cols="6" class="mb-1" >
              {{ adjustment.launch_date | onlyDate}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Valor:  
            </b-col>
            <b-col cols="6" class="mb-1" >
              {{ adjustment.value | toCurrency}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Tipo do lançamento:  
            </b-col>
            <b-col cols="6" class="mb-1" >
              {{ adjustment.credit_type | creditType}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Motivo do lançamento:  
            </b-col>
            <b-col cols="6" class="mb-1" >
              {{adjustment.adjustment_reason? adjustment.adjustment_reason.name:''}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Data de faturamento:  
            </b-col>
            <b-col cols="6" class="mb-1" >
              {{ adjustment.billing_date | onlyDate}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Criado por:  
            </b-col>
            <b-col cols="6" class="mb-1" >
              {{ adjustment.user.name }} 
            </b-col>
            <b-col cols="12" class="mb-1">
              Comentário: 
            </b-col>
            <b-col cols="12" class="mb-1">
              <span v-html="adjustment.comment"></span>
            </b-col>
           
          </b-row>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton, BFormInput, BFormTextarea, BCol, BRow
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow
  },
  directives: { 
    Ripple,
  },
  data() {
    return {
      adjustment: undefined
    }
  },
  setup() {
    return { toast: useToast()};
  },
  computed: {
    ...mapGetters({
      adjustmentDetailsSidebar: types.FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR
    })
  },
  methods: {
    ...mapMutations({
      mutateAdjustmentDetailsSidebar: types.MUTATE_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR
    }),
    ...mapActions({
      getAdjustment: types.GET_FINANCIAL_ADJUSTMENT,
    }),
    onShow(){
      this.getAdjustment(this.adjustmentDetailsSidebar.id)
        .then(response => {
          if(response.data.id){
            this.adjustment = response.data
          }else{
            throw new DOMException("Report não encontrado.")
          }
          
        })
        .catch(err => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os detalhes do ajute. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.hide
        })
    },
    clear(){
      this.adjustment = undefined
    }
  }
}
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
